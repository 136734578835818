
import { defineComponent } from "vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import MenuEntry from "@/components/member/MenuEntry.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Setting",
  components: {
    BaseHeader2,
    MenuEntry,
  },
  data() {
    return {
      store: useStore(),
      version: "",
    };
  },
  computed: {
    isLogin: function (): boolean {
      return this.store.getters["member/isLogin"];
    },
  },
  methods: {
    signOut() {
      this.deleteDevice();
      this.store.dispatch("member/signOut");
      this.$injectToken(null);
      this.$flutter.callHandler("updateToken", {
        access: "",
        refresh: "",
      });
      this.$router.replace("/login/before");
    },
    getVersion(value: string): void {
      this.version = value;
    },
    deleteDevice() {
      return this.$axios.delete("/device/mobile");
    },
  },
  mounted() {
    window.getPreferenceCallback = this.getVersion;
    this.$flutter.callHandler("getPreference", { key: "version" });
  },
});
