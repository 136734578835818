import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9dcd4906"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "setting" }
const _hoisted_2 = {
  key: 0,
  class: "signout"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader2 = _resolveComponent("BaseHeader2")!
  const _component_MenuEntry = _resolveComponent("MenuEntry")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseHeader2, {
      borderBottomColor: 
        _ctx.$isDarkMode() ? 'var(--grey3-color)' : 'var(--grey2-color)'
      ,
      borderBottomHeight: "1px"
    }, {
      "slot-middle": _withCtx(() => [
        _createTextVNode("환경 설정")
      ]),
      _: 1
    }, 8, ["borderBottomColor"]),
    _createVNode(_component_MenuEntry, {
      isEnabledAppendIcon: false,
      version: _ctx.version
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 앱 버전 ")
      ]),
      _: 1
    }, 8, ["version"]),
    _createVNode(_component_router_link, { to: "/notice" }, {
      default: _withCtx(() => [
        _createVNode(_component_MenuEntry, null, {
          default: _withCtx(() => [
            _createTextVNode("공지 사항")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/alert/setting" }, {
      default: _withCtx(() => [
        _createVNode(_component_MenuEntry, null, {
          default: _withCtx(() => [
            _createTextVNode("알림 설정")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/setting/agreement" }, {
      default: _withCtx(() => [
        _createVNode(_component_MenuEntry, null, {
          default: _withCtx(() => [
            _createTextVNode("이용약관")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.isLogin)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args)))
          }, "로그아웃")
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLogin)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          class: "quit",
          to: "/setting/quit"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 회원 탈퇴 ")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}